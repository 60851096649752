import Echo from "laravel-echo"
import Pusher from 'pusher-js'

export default {
  install(Vue) {
    Vue.echo = new Echo({
      broadcaster: 'pusher',
      key: '5a50298f31ab59ca1be8',
      cluster: 'ap2',
      forceTLS: false,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('realhub_token')}`,
          Accept: 'application/json'
        },
      },
      authEndpoint: `${process.env.VUE_APP_API_URL}/api/broadcasting/auth`,
    })

    Vue.pusher = Pusher

    Object.defineProperties(Vue.prototype, {
      $echo: {
        get() {
          return Vue.echo;
        },
      },
    });
  }
}