<template>
  <a :href="terms.url" target="_blank">{{ terms.title }}</a>
</template>

<script>
export default {
  mounted() {
    let terms = this.$store.getters.getDocuments.filter(doc => {
      return doc.default
    })

    if(terms) {
      this.terms = terms[0]
    }
  },

  data() {
    return {
      terms: ''
    }
  }
}
</script>