export default {
  state: {
    documents: []
  },

  getters: {
    getDocuments(state) {
      return state.documents;
    }
  },

  mutations: {
    setDocuments(state, data) {
      state.documents = data;
    }
  },

  actions: {
    setDocuments({ commit }, data) {
      commit('setDocuments', data);
    }
  }
}