export default {
  install(Vue) {
    Vue.loader = {
      start(type = 'default') {
        let el = document.createElement('div')
        el.classList.add('loader-element')
        el.classList.add('loader')
        el.classList.add(type)

        let spinner = document.createElement('div')
        spinner.setAttribute('uk-spinner', '')

        let logo = document.createElement('img')
        logo.src = '/logo.png'
        logo.classList.add('logo')

        el.append(logo)
        el.append(spinner)
        document.body.prepend(el)
      },

      stop() {
        Array.from(document.getElementsByClassName('loader-element')).forEach(el => {
          el.remove()
        })
      }
    }

    Object.defineProperties(Vue.prototype, {
      $loader: {
        get() {
          return Vue.loader;
        },
      },
    });
  }
}