<template>
  <div>
    <p class="m-0" @click.prevent="copyReferralLink">
      <i class="lni lni-clipboard"></i>
      <span id="referral_link">{{ referral_link }}</span>
      <span class="badge badge-primary ms-5" style="cursor: pointer">Copy</span>
      <span class="badge badge-primary d-none ms-2" id="copied-badge">Copied</span>
    </p>
    <div id="social-sharing" class="pt-0">
      <p>Share:</p>
      <ShareNetwork
        network="facebook"
        :url="referral_link"
        title="Join Myforexglobal"
        description="Join Myforexglobal"
        quote="Join Myforexglobal"
        hashtags="#myforexglobal #mfg #forex"
      >
        <i class="lni lni-facebook"></i>
      </ShareNetwork>
      <ShareNetwork
        network="twitter"
        :url="referral_link"
        title="Join Myforexglobal"
        description="Join Myforexglobal"
        quote="Join Myforexglobal"
        hashtags="#myforexglobal #mfg #forex"
      >
        <i class="lni lni-twitter"></i>
      </ShareNetwork>
      <ShareNetwork
        network="email"
        :url="referral_link"
        title="Join Myforexglobal"
        description="Join Myforexglobal"
        quote="Join Myforexglobal"
        hashtags="#myforexglobal #mfg #forex"
      >
        <i class="lni lni-envelope"></i>
      </ShareNetwork>
      <ShareNetwork
        network="whatsapp"
        :url="referral_link"
        title="Join Myforexglobal"
        description="Join Myforexglobal"
        quote="Join Myforexglobal"
        hashtags="#myforexglobal #mfg #forex"
      >
        <i class="lni lni-whatsapp"></i>
      </ShareNetwork>
      <ShareNetwork
        network="sms"
        :url="referral_link"
        title="Join Myforexglobal"
        description="Join Myforexglobal"
        quote="Join Myforexglobal"
        hashtags="#myforexglobal #mfg #forex"
      >
        <i class="lni lni-bubble"></i>
      </ShareNetwork>
      <ShareNetwork
        network="telegram"
        :url="referral_link"
        title="Join Myforexglobal"
        description="Join Myforexglobal"
        quote="Join Myforexglobal"
        hashtags="#myforexglobal #mfg #forex"
      >
        <i class="lni lni-telegram"></i>
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      default() {
        return false;
      },
    },
  },

  methods: {
    copyReferralLink() {
      var copyText = document.getElementById("referral_link");
      navigator.clipboard.writeText(copyText.innerHTML);

      if (document.selection) {
        let range = document.body.createTextRange();
        range.moveToElementText(copyText);
        range.select();
      } else if (window.getSelection) {
        let range = document.createRange();
        range.selectNode(copyText);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }

      document.getElementById("copied-badge").classList.remove("d-none");

      setTimeout(() => {
        document.getElementById("copied-badge").classList.add("d-none");
      }, 5000);
    },
  },

  computed: {
    referral_link() {
      let referral_code = this.code
        ? this.code
        : this.$store.getters.getAuthUser.referral_code;

      return `https://realhub.co.ke/register?referral_code=${referral_code}`;
    },
  },
};
</script>
