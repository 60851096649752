<template>
  <section class="theme-bg call-to-act-wrap">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="call-to-act">
            <div class="call-to-act-head">
              <h3>Post your property on RealHub</h3>
              <span>We'll help you get to potential clients much faster.</span>
            </div>
            <router-link
              :to="{ name: 'submit-property' }"
              class="btn btn-call-to-act"
              >Submit Property</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
