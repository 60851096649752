export default [
  {
    path: '',
    name: 'admin.dashboard',
    component: () => import(/* webpackChunkName: "admin.home" */ '../views/admin/Dashboard.vue')
  },
  {
    path: 'amenities',
    component: () => import(/* webpackChunkName: "admin.amenities" */ '../views/admin/Amenities.vue'),
    children: [
      {
        path: '',
        name: 'admin.amenities.index',
        component: () => import(/* webpackChunkName: "admin.amenities.index" */ '../views/admin/amenities/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.amenities.create',
        component: () => import(/* webpackChunkName: "admin.amenities.create" */ '../views/admin/amenities/Create.vue')
      },
    ]
  },
  {
    path: 'announcements',
    component: () => import(/* webpackChunkName: "admin.announcements" */ '../views/admin/Announcements.vue'),
    children: [
      {
        path: '',
        name: 'admin.announcements.index',
        component: () => import(/* webpackChunkName: "admin.announcements.index" */ '../views/admin/announcements/Index.vue')
      },
      {
        path: 'create/sms',
        name: 'admin.announcements.create-sms',
        component: () => import(/* webpackChunkName: "admin.announcements.create" */ '../views/admin/announcements/CreateSMS.vue')
      },
      {
        path: 'create/email',
        name: 'admin.announcements.create-email',
        component: () => import(/* webpackChunkName: "admin.announcements.create" */ '../views/admin/announcements/CreateEmail.vue')
      },
      {
        path: ':id',
        name: 'admin.announcements.show',
        component: () => import(/* webpackChunkName: "admin.announcements.show" */ '../views/admin/announcements/Show.vue')
      }
    ]
  },
  {
    path: 'agencies',
    component: () => import(/* webpackChunkName: "admin.agencies" */ '../views/admin/Agencies.vue'),
    children: [
      {
        path: '',
        name: 'admin.agencies.index',
        component: () => import(/* webpackChunkName: "admin.agencies.index" */ '../views/admin/agencies/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.agencies.show',
        component: () => import(/* webpackChunkName: "admin.agencies.show" */ '../views/admin/agencies/Show.vue')
      },
    ]
  },
  {
    path: 'categories',
    component: () => import(/* webpackChunkName: "admin.categories" */ '../views/admin/Categories.vue'),
    children: [
      {
        path: '',
        name: 'admin.categories.index',
        component: () => import(/* webpackChunkName: "admin.categories.index" */ '../views/admin/categories/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.categories.create',
        component: () => import(/* webpackChunkName: "admin.categories.create" */ '../views/admin/categories/Create.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.categories.edit',
        component: () => import(/* webpackChunkName: "admin.categories.edit" */ '../views/admin/categories/Edit.vue')
      },
    ]
  },
  {
    path: 'details',
    component: () => import(/* webpackChunkName: "admin.details" */ '../views/admin/Details.vue'),
    children: [
      {
        path: '',
        name: 'admin.details.index',
        component: () => import(/* webpackChunkName: "admin.details.index" */ '../views/admin/details/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.details.create',
        component: () => import(/* webpackChunkName: "admin.details.create" */ '../views/admin/details/Create.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.details.edit',
        component: () => import(/* webpackChunkName: "admin.details.edit" */ '../views/admin/details/Edit.vue')
      },
    ]
  },
  {
    path: 'payments',
    component: () => import(/* webpackChunkName: "admin.payments" */ '../views/admin/Payments.vue'),
    children: [
      {
        path: '',
        name: 'admin.payments.index',
        component: () => import(/* webpackChunkName: "admin.payments.index" */ '../views/admin/payments/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.payments.show',
        component: () => import(/* webpackChunkName: "admin.payments.show" */ '../views/admin/payments/Show.vue')
      },
    ]
  },
  {
    path: 'pricing',
    component: () => import(/* webpackChunkName: "admin.pricing" */ '../views/admin/Pricing.vue'),
    children: [
      {
        path: '',
        name: 'admin.pricing.index',
        component: () => import(/* webpackChunkName: "admin.pricing.index" */ '../views/admin/pricing/Index.vue')
      },
      {
        path: 'edit/:type',
        name: 'admin.pricing.edit',
        component: () => import(/* webpackChunkName: "admin.pricing.edit" */ '../views/admin/pricing/Edit.vue')
      },
    ]
  },
  {
    path: 'painting-and-cleaning',
    component: () => import(/* webpackChunkName: "admin.painting-and-cleaning" */ '../views/admin/PaintingAndCleaning.vue'),
    children: [
      {
        path: '',
        name: 'admin.painting-and-cleaning.index',
        component: () => import(/* webpackChunkName: "admin.painting-and-cleaning.index" */ '../views/admin/painting-and-cleaning/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.painting-and-cleaning.show',
        component: () => import(/* webpackChunkName: "admin.painting-and-cleaning.show" */ '../views/admin/painting-and-cleaning/Show.vue')
      },
    ]
  },
  {
    path: 'movers',
    component: () => import(/* webpackChunkName: "admin.movers" */ '../views/admin/Movers.vue'),
    children: [
      {
        path: '',
        name: 'admin.movers.index',
        component: () => import(/* webpackChunkName: "admin.movers.index" */ '../views/admin/movers/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.movers.create',
        component: () => import(/* webpackChunkName: "admin.movers.create" */ '../views/admin/movers/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.movers.show',
        component: () => import(/* webpackChunkName: "admin.movers.show" */ '../views/admin/movers/Show.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.movers.edit',
        component: () => import(/* webpackChunkName: "admin.movers.edit" */ '../views/admin/movers/Edit.vue')
      },
    ]
  },
  {
    path: 'legal-and-surveying',
    component: () => import(/* webpackChunkName: "admin.legal-and-surveying" */ '../views/admin/LegalAndSurveying.vue'),
    children: [
      {
        path: '',
        name: 'admin.legal-and-surveying.index',
        component: () => import(/* webpackChunkName: "admin.legal-and-surveying.index" */ '../views/admin/legal-and-surveying/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.legal-and-surveying.show',
        component: () => import(/* webpackChunkName: "admin.legal-and-surveying.show" */ '../views/admin/legal-and-surveying/Show.vue')
      },
    ]
  },
  {
    path: 'content',
    component: () => import(/* webpackChunkName: "admin.content" */ '../views/admin/Content.vue'),
    children: [
      {
        path: 'about',
        name: 'admin.content.about',
        component: () => import(/* webpackChunkName: "admin.content.about" */ '../views/admin/content/About.vue')
      },
      {
        path: 'contact',
        name: 'admin.content.contact',
        component: () => import(/* webpackChunkName: "admin.content.contact" */ '../views/admin/content/Contact.vue')
      },
      {
        path: 'faq',
        name: 'admin.content.faq',
        component: () => import(/* webpackChunkName: "admin.content.faq" */ '../views/admin/content/FAQ.vue')
      },
      {
        path: 'documents',
        name: 'admin.content.documents',
        component: () => import(/* webpackChunkName: "admin.content.documents" */ '../views/admin/content/Documents.vue')
      },
    ]
  },
  {
    path: 'properties',
    component: () => import(/* webpackChunkName: "admin.properties" */ '../views/admin/Properties.vue'),
    children: [
      {
        path: '',
        name: 'admin.properties.index',
        component: () => import(/* webpackChunkName: "admin.properties.index" */ '../views/admin/properties/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.properties.show',
        component: () => import(/* webpackChunkName: "admin.properties.show" */ '../views/admin/properties/Show.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.properties.edit',
        component: () => import(/* webpackChunkName: "admin.properties.edit" */ '../views/admin/properties/Edit.vue')
      },
    ]
  },
  {
    path: 'regions',
    component: () => import(/* webpackChunkName: "admin.regions" */ '../views/admin/Regions.vue'),
    children: [
      {
        path: '',
        name: 'admin.regions.index',
        component: () => import(/* webpackChunkName: "admin.regions.index" */ '../views/admin/regions/Index.vue')
      }
    ]
  },
  {
    path: 'users',
    component: () => import(/* webpackChunkName: "admin.users" */ '../views/admin/Users.vue'),
    children: [
      {
        path: '',
        name: 'admin.users.index',
        component: () => import(/* webpackChunkName: "admin.users.index" */ '../views/admin/users/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.users.create',
        component: () => import(/* webpackChunkName: "admin.users.create" */ '../views/admin/users/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.users.show',
        component: () => import(/* webpackChunkName: "admin.users.show" */ '../views/admin/users/Show.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.users.edit',
        component: () => import(/* webpackChunkName: "admin.users.edit" */ '../views/admin/users/Edit.vue')
      },
    ]
  },
  {
    path: 'messages',
    component: () => import(/* webpackChunkName: "admin.messages" */ '../views/admin/Messages.vue'),
    children: [
      {
        path: '',
        name: 'admin.messages.index',
        component: () => import(/* webpackChunkName: "admin.messages.index" */ '../views/admin/messages/Index.vue')
      },
    ]
  },
  {
    path: 'data',
    component: () => import(/* webpackChunkName: "admin.data" */ '../views/admin/Data.vue'),
    children: [
      {
        path: '',
        name: 'admin.data.index',
        component: () => import(/* webpackChunkName: "admin.data.index" */ '../views/admin/data/Index.vue')
      },
      {
        path: 'site/:type',
        name: 'admin.data.site',
        component: () => import(/* webpackChunkName: "admin.data.site" */ '../views/admin/data/Site.vue')
      },
      {
        path: 'excel/:id',
        name: 'admin.data.excel',
        component: () => import(/* webpackChunkName: "admin.data.excel" */ '../views/admin/data/Excel.vue')
      },
    ]
  },
  {
    path: 'career-applications',
    component: () => import(/* webpackChunkName: "admin.career-applications" */ '../views/admin/CareerApplications.vue'),
    children: [
      {
        path: '',
        name: 'admin.career-applications.index',
        component: () => import(/* webpackChunkName: "admin.career-applications.index" */ '../views/admin/career-applications/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.career-applications.show',
        component: () => import(/* webpackChunkName: "admin.career-applications.show" */ '../views/admin/career-applications/Show.vue')
      },
    ]
  },
  {
    path: 'commissions',
    component: () => import(/* webpackChunkName: "admin.commissions" */ '../views/admin/Commissions.vue'),
    children: [
      {
        path: '',
        name: 'admin.commissions.index',
        component: () => import(/* webpackChunkName: "admin.commissions.index" */ '../views/admin/commissions/Index.vue')
      }
    ]
  },
  {
    path: 'testimonials',
    component: () => import(/* webpackChunkName: "admin.testimonials" */ '../views/admin/Testimonials.vue'),
    children: [
      {
        path: '',
        name: 'admin.testimonials.index',
        component: () => import(/* webpackChunkName: "admin.testimonials.index" */ '../views/admin/testimonials/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.testimonials.create',
        component: () => import(/* webpackChunkName: "admin.testimonials.create" */ '../views/admin/testimonials/Create.vue')
      }
    ]
  },
  {
    path: 'listing-referrals',
    component: () => import(/* webpackChunkName: "admin.listing-referrals" */ '../views/admin/ListingReferrals.vue'),
    children: [
      {
        path: '',
        name: 'admin.listing-referrals.index',
        component: () => import(/* webpackChunkName: "admin.listing-referrals.index" */ '../views/admin/listing-referrals/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.listing-referrals.show',
        component: () => import(/* webpackChunkName: "admin.listing-referrals.show" */ '../views/admin/listing-referrals/Show.vue')
      }
    ]
  },
  {
    path: 'field-assistants',
    component: () => import(/* webpackChunkName: "admin.field-assistants" */ '../views/admin/FieldAssistants.vue'),
    children: [
      {
        path: '',
        name: 'admin.field-assistants.index',
        component: () => import(/* webpackChunkName: "admin.field-assistants.index" */ '../views/admin/field-assistants/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.field-assistants.show',
        component: () => import(/* webpackChunkName: "admin.field-assistants.show" */ '../views/admin/field-assistants/Show.vue')
      },
    ]
  },
  {
    path: 'relationship-managers',
    component: () => import(/* webpackChunkName: "admin.relationship-managers" */ '../views/admin/RelationshipManagers.vue'),
    children: [
      {
        path: '',
        name: 'admin.relationship-managers.index',
        component: () => import(/* webpackChunkName: "admin.relationship-managers.index" */ '../views/admin/relationship-managers/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.relationship-managers.show',
        component: () => import(/* webpackChunkName: "admin.relationship-managers.show" */ '../views/admin/relationship-managers/Show.vue')
      },
    ]
  },
  {
    path: 'profile',
    component: () => import(/* webpackChunkName: "admin.profile" */ '../views/admin/Profile.vue'),
    children: [
      {
        path: '',
        name: 'admin.profile.index',
        component: () => import(/* webpackChunkName: "admin.profile.index" */ '../views/admin/profile/Index.vue')
      },
    ]
  },
  {
    path: 'property-feedback',
    name: 'admin.property-feedback',
    component: () => import(/* webpackChunkName: "admin.property-feedback" */ '../views/admin/PropertyFeedback.vue')
  },
  {
    path: 'notifications',
    name: 'admin.notifications',
    component: () => import(/* webpackChunkName: "admin.notifications" */ '../views/admin/Notifications.vue')
  },
  {
    path: 'search-terms',
    name: 'admin.search-terms.index',
    component: () => import(/* webpackChunkName: "admin.search-terms.index" */ '../views/admin/search-terms/Index.vue')
  },
  {
    path: '*',
    name: 'admin.error',
    component: () => import(/* webpackChunkName: "admin.error" */ '../views/admin/Error.vue')
  },
]