<template>
  <div class="main-section-loader">
    <div class="section-loader">
      <span></span>
      <span></span>
      <div v-html="message" style="margin-top: 4rem"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      default() { return '' }
    }
  }
}
</script>