import Vue from 'vue'
import Vuex from 'vuex'
import Menu from './menu'
import User from './user'
import Error from './error'
import Contact from './contact'
import Documents from './documents'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Menu, User, Error, Contact, Documents
  }
})
