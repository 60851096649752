<template>
  <div id="app">
    <template v-if="!is_loaded">
      <div id="preloader">
        <img src="/logo.png" alt="logo">
        <div class="preloader">
          <span></span>
          <span></span>
        </div>
      </div>
    </template>
    <template v-if="is_loaded">
      <router-view />
    </template>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      is_loaded: false,
    };
  },

  methods: {
    fetch() {
      this.axios.get("/api/v1/init").then(response => {
        this.$store.dispatch('setMenuCategories', response.data.menu.categories)
        this.$store.dispatch('setContact', response.data.contact)
        this.$store.dispatch('setDocuments', response.data.documents)
        this.is_loaded = true

        if (response.data.is_authenticated) {
          this.$store.dispatch("setAuthUser", response.data.user);
          this.initPusher()

          if (this.$route.meta.guest) {
            return this.$router.push({ name: "redirect" });
          }
        } else {
          if (this.$auth.hasToken()) {
            this.$auth.removeToken();
            return (window.location.href = this.$router.resolve({
              name: "login",
            }).href);
          }
        }
      });
    },

    initPusher() {
      this.$echo.join('online')
        .here(users => console.log(users))
        .joining(user => {
          console.log(user)
        })
        .leaving(user => { 
          console.log(user)
        })
    }
  },
};
</script>

<style scoped>
#preloader img {
  width: 250px;
  height: auto;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -130px;
  margin-top: -120px;
}
</style>