<template>
  <div
        class="section-area section_block-contacts section_block-contacts_mod-b triagl triagl_mod-c triagl-top triagl_bg_gray"
      >
        <div class="container">
          <div class="block-contacts text-white">
            <div class="row">
              <div class="col-lg-5 col-sm-4 col-xs-12 text-left">
                <div class="block-contacts__item">
                  <div class="block-contacts__title">
                    <i class="icon fa fa-clock-o"></i>MONDAY TO SATURDAY
                  </div>
                  <div class="block-contacts__text">9:00 AM to 7:00 PM</div>
                </div>
                <div class="block-contacts__item">
                  <div class="block-contacts__title">SUNDAY</div>
                  <div class="block-contacts__text">We Take Some Rest</div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-4 col-xs-12">
                <ul class="social-links">
                  <li class="social-links__item">
                    <a class="social-links__link text-white"
                      ><i class="icon text-white fa fa-twitter"></i
                    ></a>
                  </li>
                  <li class="social-links__item">
                    <a class="social-links__link text-white"
                      ><i class="icon text-white fa fa-facebook"></i
                    ></a>
                  </li>
                  <li class="social-links__item">
                    <a class="social-links__link text-white"
                      ><i class="icon text-white fa fa-google-plus"></i
                    ></a>
                  </li>
                  <li class="social-links__item">
                    <a class="social-links__link text-white"
                      ><i class="icon text-white fa fa-linkedin"></i
                    ></a>
                  </li>
                  <li class="social-links__item">
                    <a class="social-links__link text-white"
                      ><i class="icon text-white fa fa-pinterest-p"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-5 col-sm-4 col-xs-12 text-right">
                <div class="block-contacts__phone">
                  {{ contact.phone_number
                  }}<i class="icon fa fa-phone-square"></i>
                </div>
                <div class="block-contacts__text">
                  Ask Questions or Need a Free Estimate?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  computed: {
    contact() {
      return this.$store.getters.getContact;
    },
  },
}
</script>