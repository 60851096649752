import Vue from 'vue'
import VueRouter from 'vue-router'
import admin_routes from './admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/public/Home.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Redirect.vue')
  },
  {
    path: '/verify-phone',
    name: 'verify-phone',
    component: () => import(/* webpackChunkName: "verify-phone" */ '../views/VerifyPhone.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/public/auth/Register.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/register-agency',
    name: 'register-agency',
    component: () => import(/* webpackChunkName: "register" */ '../views/public/auth/RegisterAgency.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/public/auth/ForgotPassword.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/email-verification/:token',
    name: 'email-verification',
    component: () => import(/* webpackChunkName: "email-verification" */ '../views/public/auth/EmailVerification.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/public/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/public/ContactUs.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/public/FAQs.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/public/Pricing.vue')
  },
  {
    path: '/agencies',
    name: 'agencies',
    component: () => import(/* webpackChunkName: "agencies" */ '../views/public/Agencies.vue')
  },
  {
    path: '/recover-account/:token',
    name: 'recover-account',
    component: () => import(/* webpackChunkName: "recover-account" */ '../views/public/auth/RecoverAccount.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/public/auth/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/submit-property',
    name: 'submit-property',
    component: () => import(/* webpackChunkName: "submit-property" */ '../views/public/SubmitProperty.vue')
  },
  {
    path: '/add-agency',
    name: 'add-agency',
    component: () => import(/* webpackChunkName: "add-agency" */ '../views/public/AddAgency.vue')
  },
  {
    path: '/payment/:id',
    name: 'payment',
    component: () => import(/* webpackChunkName: "payment" */ '../views/public/submit-property/Payment.vue')
  },
  {
    path: '/promote/:id',
    name: 'promote',
    component: () => import(/* webpackChunkName: "promote" */ '../views/public/submit-property/Promote.vue')
  },
  {
    path: '/my-listings',
    component: () => import(/* webpackChunkName: "my-listings" */ '../views/public/MyListings.vue'),
    children: [
      {
        path: '',
        name: 'my-listings.index',
        component: () => import(/* webpackChunkName: "my-listings.index" */ '../views/public/my-listings/Index.vue')
      },
      {
        path: ':slug',
        name: 'my-listings.show',
        component: () => import(/* webpackChunkName: "my-listings.show" */ '../views/public/my-listings/Show.vue')
      },
      {
        path: ':slug/edit',
        name: 'my-listings.edit',
        component: () => import(/* webpackChunkName: "my-listings.edit" */ '../views/public/my-listings/Edit.vue')
      },
    ]
  },
  {
    path: '/my-account',
    component: () => import(/* webpackChunkName: "my-account" */ '../views/public/MyAccount.vue'),
    children: [
      {
        path: '',
        name: 'my-account.home',
        component: () => import(/* webpackChunkName: "my-account.home" */ '../views/public/my-account/Home.vue')
      },
      {
        path: 'field-assistants',
        component: () => import(/* webpackChunkName: "my-account.field-assistants" */ '../views/public/my-account/FieldAssistants.vue'),
        children: [
          {
            path: '',
            name: 'my-account.field-assistants.index',
            component: () => import(/* webpackChunkName: "my-account.field-assistants.index" */ '../views/public/my-account/field-assistants/Index.vue')
          },
          {
            path: ':id',
            name: 'my-account.field-assistants.show',
            component: () => import(/* webpackChunkName: "my-account.field-assistants.show" */ '../views/public/my-account/field-assistants/Show.vue')
          },
        ]
      },
      {
        path: 'earnings',
        component: () => import(/* webpackChunkName: "my-account.earnings" */ '../views/public/my-account/Earnings.vue'),
        children: [
          {
            path: '',
            name: 'my-account.earnings.index',
            component: () => import(/* webpackChunkName: "my-account.earnings.index" */ '../views/public/my-account/earnings/Index.vue')
          }
        ]
      },
      {
        path: 'agencies',
        component: () => import(/* webpackChunkName: "my-account.agencies" */ '../views/public/my-account/Agencies.vue'),
        children: [
          {
            path: '',
            name: 'my-account.agencies.index',
            component: () => import(/* webpackChunkName: "my-account.agencies" */ '../views/public/my-account/agencies/Index.vue')
          },
          {
            path: 'create',
            name: 'my-account.agency.create',
            component: () => import(/* webpackChunkName: "my-account.agency" */ '../views/public/my-account/agencies/Create.vue')
          },
          {
            path: ':id',
            name: 'my-account.agency.show',
            component: () => import(/* webpackChunkName: "my-account.agency" */ '../views/public/my-account/agencies/Show.vue')
          }
        ]
      },
      {
        path: 'profile',
        name: 'my-account.profile',
        component: () => import(/* webpackChunkName: "my-account.profile" */ '../views/public/my-account/Profile.vue')
      },
      {
        path: 'messages',
        component: () => import(/* webpackChunkName: "messages" */ '../views/public/Messages.vue'),
        children: [
          {
            path: '',
            name: 'my-account.messages',
            component: () => import(/* webpackChunkName: "my-account.messages" */ '../views/public/messages/Index.vue')
          },
        ]
      },
      {
        path: 'customers',
        name: 'my-account.customers',
        component: () => import(/* webpackChunkName: "my-account.customers" */ '../views/public/my-account/Customers.vue')
      },
      {
        path: 'bookmarks',
        name: 'my-account.bookmarks',
        component: () => import(/* webpackChunkName: "my-account.bookmarks" */ '../views/public/my-account/Bookmarks.vue')
      },
      {
        path: 'password',
        name: 'my-account.password',
        component: () => import(/* webpackChunkName: "my-account.password" */ '../views/public/my-account/Password.vue')
      }
    ]
  },
  {
    path: '/movers',
    component: () => import(/* webpackChunkName: "movers" */ '../views/public/Movers.vue'),
    children: [
      {
        path: '',
        name: 'movers',
        component: () => import(/* webpackChunkName: "movers" */ '../views/public/movers/Home.vue')
      },
      {
        path: ':id',
        name: 'movers.show',
        component: () => import(/* webpackChunkName: "movers.show" */ '../views/public/movers/Show.vue')
      },
    ]
  },
  {
    path: '/testimonials',
    component: () => import(/* webpackChunkName: "testimonials" */ '../views/public/Testimonials.vue'),
    children: [
      {
        path: '',
        name: 'testimonials',
        component: () => import(/* webpackChunkName: "testimonials" */ '../views/public/testimonials/Home.vue')
      },
      {
        path: 'create',
        name: 'testimonials.create',
        component: () => import(/* webpackChunkName: "testimonials.create" */ '../views/public/testimonials/Create.vue')
      }
    ]
  },
  {
    path: '/careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/public/Careers.vue'),
    children: [
      {
        path: '',
        name: 'careers',
        component: () => import(/* webpackChunkName: "careers" */ '../views/public/careers/Home.vue')
      },
      {
        path: 'field-assistant',
        name: 'careers.field-assistant',
        component: () => import(/* webpackChunkName: "field-assistant" */ '../views/public/careers/FieldAssistant.vue')
      },
      {
        path: 'relationship-manager',
        name: 'careers.relationship-manager',
        component: () => import(/* webpackChunkName: "field-assistant" */ '../views/public/careers/RelationshipManager.vue')
      }
    ]
  },
  {
    path: '/painting-and-cleaning',
    component: () => import(/* webpackChunkName: "painting-and-cleaning" */ '../views/public/PaintingAndCleaning.vue'),
    children: [
      {
        path: '',
        name: 'painting-and-cleaning',
        component: () => import(/* webpackChunkName: "painting-and-cleaning" */ '../views/public/painting-and-cleaning/Home.vue')
      }
    ]
  },
  {
    path: '/legal-and-surveying',
    component: () => import(/* webpackChunkName: "legal-and-surveying" */ '../views/public/LegalAndSurveying.vue'),
    children: [
      {
        path: '',
        name: 'legal-and-surveying',
        component: () => import(/* webpackChunkName: "legal-and-surveying" */ '../views/public/legal-and-surveying/Home.vue')
      }
    ]
  },
  {
    path: '/seller-plans',
    component: () => import(/* webpackChunkName: "seller-plans" */ '../views/public/SellerPlans.vue'),
    children: [
      {
        path: '',
        name: 'seller-plans',
        component: () => import(/* webpackChunkName: "seller-plans" */ '../views/public/seller-plans/Home.vue')
      },
      {
        path: 'book/:id',
        name: 'seller-plans.book',
        component: () => import(/* webpackChunkName: "seller-plans.book" */ '../views/public/seller-plans/Book.vue')
      }
    ]
  },
  {
    path: '/refer-and-earn',
    component: () => import(/* webpackChunkName: "refer-and-earn" */ '../views/public/ReferAndEarn.vue'),
    children: [
      {
        path: '',
        name: 'refer-and-earn',
        component: () => import(/* webpackChunkName: "refer-and-earn" */ '../views/public/refer-and-earn/Home.vue')
      }
    ]
  },
  {
    path: '/field-assistants',
    component: () => import(/* webpackChunkName: "field-assistants" */ '../views/public/FieldAssistants.vue'),
    children: [
      {
        path: '',
        name: 'field-assistants',
        component: () => import(/* webpackChunkName: "field-assistants" */ '../views/public/field-assistants/Home.vue')
      },
      {
        path: 'book/:id',
        name: 'field-assistants.book',
        component: () => import(/* webpackChunkName: "field-assistants.book" */ '../views/public/field-assistants/Book.vue')
      }
    ]
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/public/docs/TermsAndConditions.vue')
  },
  {
    path: '/return-and-refund-policy',
    name: 'return-and-refund-policy',
    component: () => import(/* webpackChunkName: "return-and-refund-policy" */ '../views/public/docs/ReturnAndRefundPolicy.vue')
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import(/* webpackChunkName: "cookie-policy" */ '../views/public/docs/CookiePolicy.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/public/docs/PrivacyPolicy.vue')
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import(/* webpackChunkName: "disclaimer" */ '../views/public/docs/Disclaimer.vue')
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    children: admin_routes
  },
  {
    path: '/:slug',
    name: 'page',
    component: () => import(/* webpackChunkName: "page" */ '../views/public/Page.vue')
  },
  {
    path: '/*',
    component: () => import(/* webpackChunkName: "error-404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
		return { x: 0, y: 0 };
	},
  routes
})

export default router
