<template>
  <a
    href="#"
    class="edit-button"
    v-if="is_admin"
    @click.prevent="$emit('click')"
    >Edit</a
  >
</template>

<script>
export default {
  computed: {
    is_admin() {
      return this.$store.getters.getAuthUser.role == "admin";
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-button {
  padding: 15px;
  position: absolute;
  right: 0;
  background: #fff;
  margin: 15px;
  color: #404040;
  z-index: 999999;
  border: solid 1px #ddd;
  display: none;
}

.has-edit:hover {
  .edit-button {
    display: block;
  }
}
</style>