<template>
  <div class="pt-4 has-edit">
    <edit-button v-if="!edit_partners" @click="edit_partners = true" />

    <div class="container">
      <a href="#" class="float-right float-end text-white" v-if="edit_partners" @click.prevent="partners.push({})"><i class="lni lni-plus mr-2"></i>Add Partner</a>
      <h4>Our Partners</h4>
      <div class="row mt-4" v-if="! edit_partners">
        <div
          class="col-md-2"
          v-for="(partner, i) in partners"
          :key="`partne-${i}`"
        >
          <a :href="partner.link" target="_blank">
            <img
              v-if="partner.type == 'image'"
              :src="partner.content"
              :alt="partner.content"
              style="height: 50px; max-width: 100%"
            />
            <h4 v-if="partner.type == 'text'" class="pt-2">
              {{ partner.content }}
            </h4>
          </a>
        </div>
      </div>
      <div class="row mt-4" v-if="edit_partners">
        <div class="col-md-3" v-for="(partner, i) in partners" :key="`partner-${i}`">
          <div class="form-group">
            <label class="text-white">Type</label>
            <select v-model="partner.type" class="form-control">
              <option value="image">Image</option>
              <option value="text">Text</option>
            </select>
          </div>
          <div class="form-group" v-if="partner.type == 'text'">
            <label class="text-white">Title</label>
            <input type="text" v-model="partner.content" placeholder="Link" class="form-control">
            <input type="file" ref="partner_logo" style="display: none">
          </div>
          <div class="form-group" v-if="partner.type == 'image'">
            <label class="text-white">Logo <small class="text-muted">Leave blank to not change</small></label>
            <input type="file" ref="partner_logo" class="form-control">
          </div>
          <div class="form-group">
            <label class="text-white">Link</label>
            <input type="text" v-model="partner.link" placeholder="Link" class="form-control">
          </div>
          <div class="form-group text-center">
            <a href="#" @click.prevent="partners.splice(i, 1)"><i class="lni lni-trash-can mr-2"></i>Delete</a>
          </div>
        </div>
        <div class="col-md-12">
          <a href="#" class="btn btn-warning text-white" @click.prevent="edit_partners = false"><i class="lni lni-close mr-2"></i>Cancel</a>
          <a href="#" class="btn btn-success float-right float-end text-white" @click.prevent="update"><i class="lni lni-save mr-2"></i>Save</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      partners: [],
      edit_partners: false,
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/content/partners").then((response) => {
        this.partners = response.data.content.partners;
      });
    },

    update() {
      let data = new FormData
      data.append("type", "partners");
      
      this.partners.forEach((partner, i) => {
        data.append(`partners[${i}][type]`, partner.type)
        data.append(`partners[${i}][link]`, partner.link)
        data.append(`partners[${i}][content]`, partner.content)

        if(partner.type == 'image') {
          if(this.$refs.partner_logo[i].files[0]) {
            data.append(`partners[${i}][new_logo]`, this.$refs.partner_logo[i].files[0])
          }
        }
      })

      this.$axios.post('/api/v1/admin/content', data).then(() => {
        this.edit_partners = false
        this.fetch()
      })
    }
  },
};
</script>
