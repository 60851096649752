<template>
  <div>
    <a href="#" @click.prevent="rating = i" v-for="i in 5" :key="`rating-${i}`">
      <i :class="`lni lni-star${i <= rating ? '-filled' : ''}`"></i>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rating: this.value
    }
  },

  props: {
    value: {
      default() { return 0 }
    }
  },

  watch: {
    rating() {
      this.$emit('input', this.rating)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  margin-right: 10px;
}
</style>