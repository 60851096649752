import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Plugins
import Loader from './plugins/loader'
Vue.use(Loader)

import Auth from './plugins/auth'
Vue.use(Auth)

import Errors from './plugins/errors'
Vue.use(Errors)

import Magnific from './plugins/magnific'
Vue.use(Magnific)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

import Echo from './plugins/echo'
Vue.use(Echo)

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#3d0859'
})

import DownloadCsv from './plugins/download-csv'
Vue.use(DownloadCsv)

// Components
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import VLazyImage from "v-lazy-image/v2"
Vue.component('v-image', VLazyImage)

import Loading from './components/Loading'
Vue.component('loading', Loading)

import EditButton from './components/EditButton'
Vue.component('edit-button', EditButton)

import Pagination from 'vue-pagination-2'
Vue.component('pagination', Pagination)

import Modal from './components/Modal'
Vue.component('modal', Modal)

import Alert from './components/Alert'
Vue.component('alert', Alert)

import InputRating from './components/InputRating'
Vue.component('input-rating', InputRating)

import Partners from './components/Partners'
Vue.component('partners', Partners)

import CallToAction from './components/CallToAction'
Vue.component('call-to-action', CallToAction)

import Rating from './components/Rating'
Vue.component('rating', Rating)

import TextEdit from './components/TextEdit'
Vue.component('text-edit', TextEdit)

import SelectRegion from './components/SelectRegion'
Vue.component('select-region', SelectRegion)

import ReferralLink from './components/ReferralLink'
Vue.component('referral-link', ReferralLink)

import PrivacyPolicyUrl from './components/PrivacyPolicyUrl'
Vue.component('privacy-policy-url', PrivacyPolicyUrl)

import PageTitle from './components/PageTitle'
Vue.component('page-title', PageTitle)

import NewsLetter from './components/NewsLetter'
Vue.component('news-letter', NewsLetter)

import ServicesContacts from './components/ServicesContacts'
Vue.component('services-contacts', ServicesContacts)

import GoogleLogin from 'vue-google-login'
Vue.component('GoogleLogin', GoogleLogin)

import Editor from '@tinymce/tinymce-vue'
Vue.component('editor', Editor)

import DatePicker from 'vuejs-datepicker'
Vue.component('date-picker', DatePicker)

import Carousel from 'vue-owl-carousel'
Vue.component('carousel', Carousel)

// require('./theme')
window.jQuery = window.$ = require('jquery')
require('@popperjs/core')
require('bootstrap')

/* eslint-disable-next-line */
router.beforeEach((to, from, next) => {
  let referral_code = from.query.referral_code
  
  if(referral_code) {
    next({ query: from.query })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')