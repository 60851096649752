export default {
  install(Vue) {
    Vue.errors = {
      handle(error) {
        Array.from(document.getElementsByClassName('form-option-error')).forEach(err => {
          err.remove()
        })

        if(error.response.status == 422) {
          let data = error.response.data.errors
          Object.keys(data).forEach((the_error) => { 
            data[the_error].forEach(el => {
              let element = document.getElementById(`form-option-${the_error}`)

              if(element) {
                let parent = element.parentElement 
                let message = document.createElement('span')
                message.innerHTML = el
                message.classList.add('text-danger')
                message.classList.add('form-option-error')
                parent.append(message)
              }
            })
          })
        }
      }
    }

    Object.defineProperties(Vue.prototype, {
      $errors: {
        get() {
          return Vue.errors;
        },
      },
    });
  }
}