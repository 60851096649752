<template>
  <div class="d-inline">
    <a href="#" @click.prevent="download" class="text-tiny"><i class="lni lni-download mr-2"></i>Download</a>
  </div>
</template>

<script>
export default {
  props: {
    json: {
      default() { return [] }
    }
  },

  methods: {
    download() {
      return this.$csv.download(this.json)
    }
  }
}
</script>