import Component from "./Component.vue";
const { Parser } = require("json2csv");

export default {
  install(Vue) {
    Vue.component("download-csv", Component);

    Vue.csv = {
      download(data) {
        const fields = Object.keys(data[0]);
        const opts = { fields };

        try {
          const parser = new Parser(opts);
          const csv = parser.parse(data);

          return this.link(csv)
        } catch (err) {
          console.error(err);
        }
      },

      link(csv) {
        let file_name = new Date
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, file_name);
        } else {
          var link = document.createElement("a");

          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", file_name);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
    };

    Object.defineProperties(Vue.prototype, {
      $csv: {
        get() {
          return Vue.csv;
        },
      },
    });
  },
};
