<template>
  <div class="row">
    <div class="col-md-6">
      <v-select
        :options="regions"
        label="name"
        placeholder="Select County"
        v-model="selected_county"
      />
    </div>
    <div class="col-md-6">
      <template v-if="!adding_region">
        <input
          type="text"
          disabled
          v-if="selected_county == null"
          class="form-control"
          placeholder="Locality"
        />
        <vue-select
          v-if="selected_county != null"
          :options="selected_county.children"
          label="name"
          placeholder="Select Locality"
          v-model="selected_region"
          :county="selected_county"
          @addNew="addRegion"
        />
      </template>
      <loading v-if="adding_region" class="inline" />
    </div>
  </div>
</template>

<script>
import VueSelect from "@/components/vue-select-with-add/src/index";

export default {
  components: {
    VueSelect,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      regions: [],
      selected_county: null,
      selected_region: null,
      adding_region: false,
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/regions").then((response) => {
        this.regions = response.data.regions;
      });
    },

    addRegion(name) {
      this.adding_region = true;

      let data = {
        name: name,
        parent: this.selected_county.id,
      };

      this.$axios
        .post("/api/v1/properties/add-region", data)
        .then((response) => {
          this.selected_county.children.push(response.data.region);
          this.selected_region = response.data.region;
          this.adding_region = false;
        });
    },
  },

  watch: {
    selected_region() {
      this.$emit('input', this.selected_region == null ? null : this.selected_region.id)
    }
  }
};
</script>
