export default {
  state: {
    contact: []
  },

  getters: {
    getContact(state) {
      return state.contact;
    }
  },

  mutations: {
    setContact(state, data) {
      state.contact = data;
    }
  },

  actions: {
    setContact({ commit }, data) {
      commit('setContact', data);
    }
  }
}