<template>
  <div class="page-title">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <h2 class="ipt-title">{{ title }}</h2>
          <span class="ipn-subtitle">{{ subtitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default() { return '' }
    },

    subtitle: {
      default() { return '' }
    }
  }
};
</script>
