import $ from "jquery"
require('magnific-popup')
import 'magnific-popup/dist/magnific-popup.css'

export default {
  install(Vue) {
    Vue.magnific = {
      set(el, items) {
        let mapped_items = items.map(item => {
          return {
            src: item
          }
        })

        $(`#${el}`).magnificPopup({
          items: mapped_items,
          gallery: {
            enabled: true,
          },
          type: "image", // this is default type
        });
      },

      open(item, others) {
        let mapped_items = others.map(item => {
          return {
            src: item
          }
        })
        mapped_items.push({
          src: item
        })

        $.magnificPopup.open({
          items: mapped_items,
          gallery: {
            enabled: true,
          },
          type: 'image'
        });
      }
    };

    Object.defineProperties(Vue.prototype, {
      $magnific: {
        get() {
          return Vue.magnific;
        },
      },
    });
  },
};
