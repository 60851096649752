export default {
  state: {
    error: false
  },

  getters: {
    getError(state) {
      return state.error;
    }
  },

  mutations: {
    setError(state, data) {
      state.error = data;
    }
  },

  actions: {
    setError({ commit }, data) {
      commit('setError', data);
    }
  }
}