<template>
  <div>
    <i v-for="i in 5" :key="`rating-${i}`" :class="`lni lni-star${i <= rating ? '-filled' : ''}`"></i>
    (0 Reviews)
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      default() { return 0 }
    },
    reviews: {
      default() { return 0 }
    }
  }
}
</script>

<style lang="scss" scoped>
.lni {
  font-size: 12px;
}
</style>