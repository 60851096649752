export default {
  state: {
    menu_categories: []
  },

  mutations: {
    setMenuCategories(state, data) {
      state.menu_categories = data
    }
  },

  actions: {
    setMenuCategories({ commit }, data) {
      commit('setMenuCategories', data);
    }
  },

  getters: {
    getMenuCategories(state) {
      return state.menu_categories
    }
  }
}