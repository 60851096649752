<template>
  <div>
    <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
      v-model="text"
      :init="{
        height: height,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | image',
      }"
    />
  </div>
</template>

<script>
export default {
  mounted() {
    this.text = this.value
  },

  data() {
    return {
      text: ''
    }
  },

  props: {
    value: {
      default() { return '' }
    },

    height: {
      default() { return 350 }
    }
  },

  watch: {
    text() {
      this.$emit('input', this.text)
    }
  }
};
</script>
