<template>
  <form class="form-footer mt-2" @submit.prevent="subscribe">
    <div class="input-group">
      <input
        type="text"
        placeholder="NEWSLETTER: Your Email"
        class="form-control"
        v-model="email"
      />
      <button><i class="icon fa fa-envelope"></i></button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },

  methods: {
    subscribe() {
      this.$axios
        .post("/api/v1/newsletter/subscribe", { email: this.email })
        .then(() => {
          this.email = "";
          this.$swal("You have been added to our newsletter", "", "success");
        });
    },
  },
};
</script>
